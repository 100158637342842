import { gql, useQuery } from '@apollo/client';
import { format } from 'date-fns';

const GET_UNAVAILABLE_DATES = gql`
  query GetUnavailableDates {
    unavailableDates: getDateInspectionUnavailable
  }
`;

interface GetUnavailableDates {
  unavailableDates: string[]
}
interface Variables {
  // dateBegin: string;
}

const dateBegin = new Date().toISOString();
export function useUnavailableDates() {
  return useQuery<GetUnavailableDates, Variables>(GET_UNAVAILABLE_DATES, {
    // variables: { dateBegin },
    fetchPolicy: 'cache-first',
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
  });
}


const GET_BLOCKED_DATES = gql`
  query GetBlockedDates {
    blockedDates: getOrderServiceDateBlock {
      dateBlock
      timeBlock
    }
  }
`;

interface GetBlockedDates {
  blockedDates: { 
    dateBlock: string 
    timeBlock: string
  }[];
}

export function useBlockedDates() {
  return useQuery<GetBlockedDates>(GET_BLOCKED_DATES, {
    fetchPolicy: 'cache-first',
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
  });
}